import axios from 'axios';
import { AXIOS_CONSTANTS } from 'CONSTANTS/apiConstants';

// creating Axios instance
export const createAxiosInstance = (baseURL: string|undefined) => {
  return axios.create({
    baseURL,
    headers: {
      Accept: AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE_JSON,
      'Content-Type': AXIOS_CONSTANTS.REQUEST_HEADERS.CONTENT_TYPE_JSON,
    },
    timeout: AXIOS_CONSTANTS.REQUEST_TIMEOUT,
  });
};